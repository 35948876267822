import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import './App.css';

function App() {
    return (
        <>
        <Container className='App-header'>
            <Row>
                <Col>
                    <h1>Coming Soon..</h1>                
                </Col>
            </Row>
            
        </Container>
        </>
    )
}
export default App;